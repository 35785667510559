import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import PageNotFound from "./errors/PageNotFound";
import Home from "./Home";
import { BrandingProvider } from "../contexts/BrandingContext";
import { SnackabraProvider } from "contexts/SnackabraContext";
import { WalletProvider } from "../contexts/WalletContext";
import { NotificationProvider } from "../contexts/NotificationContext";
import { AuthProvider } from "../contexts/AuthContext";
import { config } from "config";

const SBConfig = {
    channel_server: config.channel_server,
    channel_ws: config.channel_ws,
    storage_server: config.storage_server,
}

const AppRoutes = () => {
    return (
        <BrandingProvider>
            <SnackabraProvider config={SBConfig}>
                <NotificationProvider>
                    <AuthProvider>
                        <WalletProvider>
                            <CssBaseline />
                            <Router>
                                <Routes>
                                    <Route path="/404" element={<PageNotFound />} />
                                    <Route path="/" element={<Home />} />
                                    <Route path="*" element={<PageNotFound />} />
                                </Routes>
                            </Router>
                        </WalletProvider>
                    </AuthProvider>
                </NotificationProvider>
            </SnackabraProvider>
        </BrandingProvider>
    );
};

export default AppRoutes;

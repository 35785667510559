import * as React from "react"
import { stores, SnackabraStoreType } from "react384"
import * as __ from "lib384"

export type SnackabraContextType = SnackabraStoreType | null

interface SnackabraProviderProps extends React.PropsWithChildren<{}> {
  config: __.SnackabraTypes.SBServer

}

const SnackabraContext = React.createContext<SnackabraContextType>({} as unknown as SnackabraStoreType);



export function SnackabraProvider({ children, config }: SnackabraProviderProps) {
  const [ready, setReady] = React.useState<boolean>(false)
  const [sbContext, setSBContext] = React.useState<SnackabraStoreType | null>(null)

  React.useEffect(() => {
    // this is the one global SB object for the app
    const sbContext = new stores.SB(config)
    // window.SBFileHelper = new SBFileHelper(this.props.config)
    // window.SBFileHelper.knownShards = new Map()
    // window.SBFileHelper.ignoreProcessing = new Map()
    sbContext.ready.then(() => {
      setSBContext(sbContext)
      setReady(true)

    })
  }, [ready])

  React.useEffect(() => {
    if (sbContext && ready) {
      console.warn("==== SB (Context) Store is ready")
    }
  }, [sbContext, ready])


  return (<>
    {
      ready ?
        <SnackabraContext.Provider value={sbContext}>
          {children}
        </SnackabraContext.Provider>
        : null
    }
  </>)

};

export default SnackabraContext;
import * as React from 'react';
import { CardActions, Grid, InputAdornment, TextField, Card, CardContent, CardMedia, Typography, Button } from '@mui/material';
import { Product } from './Products';


interface CheckoutFormProps {
    product: Product,
    onPurchase: CallableFunction
}

export default function CheckoutForm(props: CheckoutFormProps) {
    const [quantity, setQuantity] = React.useState<number>(1)
    const [min, setMin] = React.useState<number>(1)
    const [error, setError] = React.useState<boolean>(false)

    React.useEffect(() => {
        if (props.product === null) return
        if (props.product.price / 100 < 1) {
            let amount = 1
            while ((props.product.price * amount) / 100 < 1) {
                amount++
            }
            setMin(amount)

        }
        // console.log(props.product.price / 100)
    }, [props.product])

    React.useEffect(() => {
        if(quantity < min){
            setError(true)
        }else{
            setError(false)
        }
    }, [quantity, min])

    const updateQuantity = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value)
        setQuantity(value)
    }

    return (
        <Card elevation={3}>
            <CardMedia
                component="img"
                // sx={{ width: 151 }}
                image={props.product.image}
                alt={props.product.name}
            />
            <CardContent>
                <Grid
                    justifyContent={'space-between'}
                    container>
                    <Grid item>
                        <Typography component="div" variant="h5" noWrap>
                            {props.product.name}
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary" component="div" noWrap>
                            {"$" + (props.product.price / 100).toFixed(2)} per {props.product.unit}
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary" component="div" noWrap>
                            Minimum Quantity {min}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography component="div" variant="h5" noWrap>
                            Total
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary" component="div" noWrap>
                            {"$" + ((props.product.price * quantity) / 100).toFixed(2)}
                        </Typography>
                    </Grid>
                </Grid>

            </CardContent>
            <CardActions>
                <TextField
                    id="quantity"
                    size="small"
                    type='number'
                    fullWidth
                    error={error}
                    value={quantity}
                    sx={{ pr: 0 }}
                    onChange={updateQuantity}
                    InputProps={{
                        sx: { pr: 0 },
                        endAdornment:
                            <InputAdornment position="end">
                                <Button disabled={error} onClick={() => { props.onPurchase(quantity) }} variant={'contained'}>Buy</Button>
                            </InputAdornment>
                    }}
                />
            </CardActions>
        </Card>
    );
}
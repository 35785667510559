import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Grid, Typography, LinearProgress, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { config } from 'config';
import CheckoutForm from './CheckoutForm';
import { products } from './Products';
import CheckoutComplete from './CheckoutComplete';
import { ChannelStoreType } from 'react384';
import * as __ from 'lib384/dist/384.esm'
import WalletContext from 'contexts/WalletContext';

const stripePromise = loadStripe(config.stripe_pk);

function Checkout(props: { product_id: string, onBack: CallableFunction, channel: ChannelStoreType }) {
    const wallet = React.useContext(WalletContext)
    const channel = props.channel
    const [options, setOptions] = React.useState<null | any>(null);
    const [paymentIntent, setPaymentIntent] = React.useState<null | any>(null);
    const [quantity, setQuantity] = React.useState<number | null>(null);
    const [product, setProduct] = React.useState<any>(null);
    const [showCheckoutForm, setShowCheckoutForm] = React.useState<boolean>(false);
    const [showPaymentForm, setShowPaymentForm] = React.useState<boolean>(false);
    const [payementLoading, setPaymentLoading] = React.useState<boolean>(true);
    const appearance = {
        theme: 'stripe',
        rules: {
            '.Label': {
                color: '#fff',
            },
        }
    };

    React.useEffect(() => {
        if (props.product_id !== '') {
            for (let i in products) {
                if (products[i].product_id === props.product_id) {
                    setProduct(products[i])
                    setShowCheckoutForm(true)
                }
            }
        }
        if (props.product_id === '') {
            setShowCheckoutForm(false)
            setShowPaymentForm(false)
            setPaymentLoading(false)
            setPaymentIntent(null)
        }
    }, [props.product_id]);


    const cancelPaymentIntent = async () => {
        if (paymentIntent === null) {
            props.onBack()
            return
        }
        const enc = new TextEncoder()
        const ab = enc.encode(JSON.stringify(paymentIntent))
        const key = await __.crypto.sbCrypto.generateIdKey(ab)
        const id = __.NewSB.arrayBufferToBase64(key.id_binary)
        fetch(config.payment_api + '/cancel', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                intent_id: paymentIntent.id,
                intent_hash: id
            })
        }).then((response) => {
            if (response.status === 200) {
                return response.json()
            } else {
                throw new Error('Error canceling payment intent')
            }
        }).then(() => {
            const message = {
                text: JSON.stringify({
                    type: 'canceled-payment-intent',
                    intent: id,
                    messageType: 'canceled-payment-intent'
                })
            }
            channel.sendMessage(message).then((message) => {
                setShowCheckoutForm(false)
                setShowPaymentForm(false)
                setPaymentLoading(false)
                setPaymentIntent(null)
            })


        }).catch((error) => {
            console.log(error)
            alert('Error canceling payment intent')
        })
        props.onBack()
    }

    const updateQuantity = (quantity: number) => {
        setQuantity(quantity)
        setShowPaymentForm(true)
        setShowCheckoutForm(false)
        setPaymentLoading(true)
        fetch(config.payment_api + '/initialize', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                quantity: quantity,
                type: product.type,
                wallet_id: wallet.id
            })
        }).then((response) => {
            if (response.status === 200) {
                return response.json()
            } else {
                throw new Error('Error initializing payment intent')
            }
        }).then(async (data) => {
            const enc = new TextEncoder()
            const ab = enc.encode(JSON.stringify(data.intent.id))
            const key = await __.crypto.sbCrypto.generateIdKey(ab)
            const id = __.NewSB.arrayBufferToBase64(key.id_binary)
            const message = {
                text: JSON.stringify({
                    type: 'pending-payment-intent',
                    intent: data.intent.id,
                    messageType: 'pending-payment-intent'
                })
            }
            channel.sendMessage(message).then((message) => {

                setPaymentIntent(data.intent)
                setOptions({ clientSecret: data.intent.client_secret, appearance })
                setPaymentLoading(false)
                console.log(message)
            })

        }).catch((error) => {
            console.log(error)
            alert('Error initializing payment intent')
            props.onBack()
        })
    }

    return (
        <>
            <Grid item xs={12} sx={{ mb: 3, display: 'inline-flex' }}>

                <IconButton onClick={cancelPaymentIntent}>
                    <ArrowBackIcon />
                </IconButton>
                <Typography variant="h6">
                    Checkout
                </Typography>
            </Grid>
            {showCheckoutForm ?
                <Grid item xs={12} sm={8} md={4} lg={4} xl={3} sx={{ mb: 3 }}>
                    <CheckoutForm product={product} onPurchase={updateQuantity} />
                </Grid>
                :
                null
            }
            {!showPaymentForm ? null :
                payementLoading ?
                    <Grid item xs={12} sx={{ mb: 3 }}>
                        <LinearProgress color="inherit" />
                    </Grid> :
                    <Grid item xs={12} sx={{ m: 3 }}>
                        <Elements stripe={stripePromise} options={options}>
                            <CheckoutComplete channel={channel} />
                        </Elements>
                    </Grid>

            }
        </>
    );
};

export default Checkout;
import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button/Button';
import { Product } from './Products';

interface ProductCardProps {
    product: Product,
}

export default function ProductCard(props: ProductCardProps ) {

    const checkout = () => {
        if(props.product.checkout === undefined) throw new Error("ProductCard checkout function not defined")
        props.product.checkout(props.product.product_id)
    }

    return (
        <Card sx={{ display: 'flex' }} elevation={3}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography component="div" variant="h5" noWrap>
                        {props.product.name}
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary" component="div" noWrap>
                        {"$" + (props.product.price / 100).toFixed(2)} per {props.product.unit}
                    </Typography>
                </CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                    <Button onClick={checkout} variant={'contained'}>Buy</Button>
                </Box>
            </Box>
            <CardMedia
                component="img"
                // sx={{ width: 151 }}
                image={props.product.image}
                alt={props.product.name}
            />
        </Card>
    );
}
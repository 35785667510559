import React, {useState, useEffect} from 'react';
import {useStripe} from '@stripe/react-stripe-js';

const PaymentStatus = (props: {onError: CallableFunction, onSuccess: CallableFunction}) => {
  const stripe = useStripe();
  const [message, setMessage] = useState<string>('');

  useEffect(() => {
    if (!stripe) {
      return;
    }

    // Retrieve the "payment_intent_client_secret" query parameter appended to
    // your return_url by Stripe.js
    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    );

    // Retrieve the PaymentIntent
    stripe
      .retrievePaymentIntent(clientSecret as string)
      .then(({paymentIntent}) => {
        // Inspect the PaymentIntent `status` to indicate the status of the payment
        // to your customer.
        //
        // Some payment methods will [immediately succeed or fail][0] upon
        // confirmation, while others will first enter a `processing` state.
        //
        // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
        if(!paymentIntent){
            props.onError();
            setMessage('Something went wrong.');
            return;
        }
        switch (paymentIntent.status) {
          case 'succeeded':
            props.onSuccess();
            setMessage('Success! Payment received. Your order will be processed shortly.');
            break;

          case 'processing':
            props.onSuccess();
            setMessage("Payment processing. When your order has been processed.");
            break;

          case 'requires_payment_method':
            // Redirect your user back to your payment page to attempt collecting
            // payment again
            props.onError();
            setMessage('Payment failed. Please try another payment method.');
            break;

          default:
            props.onError();
            setMessage('Something went wrong.');
            break;
        }
      });
  }, [stripe]);


  return message;
};

export default PaymentStatus;